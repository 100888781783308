<template>
  <div id="Index">
    <div @click="musicOnClick" class="music-bg music-bg-run">
      <img src="../../assets/images/new/music-bg.png" alt="" />
    </div>
    <MeanHeader
      v-if="
        ![
          'About',
          'Warehouse',
          'BagRecharge',
          'shareReward',
          'Tipspage',
          'fusion',
        ].includes($route.name)
      " />
    <!-- <swiper/> -->
    <!-- <nav-header></nav-header> -->
    <div class="out-bj">
      <keep-alive include="Home">
        <router-view id="output"></router-view>
      </keep-alive>
    </div>

    <div
      v-show="showScrollTopBtn"
      @click="scrollTopOnClick"
      class="scroll-top"></div>
    <!-- <public-footer></public-footer> -->
    <div id="footer"></div>
  </div>
</template>

<script>
// 进入的首页
// import NavHeader from "@/components/common/NavHeader/NavHeader.vue";
import MeanHeader from "@/components/common/MeanHeader.vue";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import { mapActions, mapState } from "vuex";
// import PublicFooter from "@/components/common/PublicFooter/PublicFooter.vue";
export default {
  name: "Index",
  data() {
    return {
      showScrollTopBtn: false,
      isGlobalMusicPaused: true,
    };
  },
  computed: {
    ...mapState(["globalMusic"]),
  },
  created() {
    if (!localStorage.getItem("showTips0818")) {
      this.$confirm(
        "近期由于某些饰品稀有度值增高，个别箱子价格小幅提升，这将会是一个短期行为，同时能够保证用户开箱的公平性不受任何的影响。",
        "网站公告",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "不再提示",
          cancelButtonText: "确定",
        }
      )
        .then(() => {
          console.log("不再提示");
          localStorage.setItem("showTips0818", true);
        })
        .catch(() => {
          console.log("确定");
        });
    }
    this.Init();
  },
  watch: {
    isGlobalMusicPaused() {
      if (!this.isGlobalMusicPaused) {
        document.querySelector(".music-bg-run").style.animationPlayState =
          "running";
      } else {
        document.querySelector(".music-bg-run").style.animationPlayState =
          "paused";
      }
    },
  },
  methods: {
    ...mapActions({
      MyInfo: "Refresh",
    }),
    musicOnClick() {
      if (this.globalMusic.paused) {
        this.globalMusic.play();
        this.globalMusic.loop = true;
        this.isGlobalMusicPaused = false;
      } else {
        this.globalMusic.pause();
        this.isGlobalMusicPaused = true;
      }
    },
    Init() {
      // let that = this;
      // function playMusic() {
      //   that.globalMusic.play();
      //   that.globalMusic.loop = true;
      //   that.globalMusic.volume = 1;
      //   that.isGlobalMusicPaused = false;
      //   setTimeout(() => {
      //     removeEventListener("click", playMusic);
      //   }, 1000);
      // }
      // addEventListener("click", playMusic);

      this.MyInfo();
      window.addEventListener("scroll", () => {
        this.showScrollTopBtn = false;
        if (document.documentElement.scrollTop > 800) {
          this.showScrollTopBtn = true;
        }
      });
    },
    scrollTopOnClick() {
      const timeTop = setInterval(() => {
        let top = document.documentElement?.scrollTop || 0;
        document.documentElement.scrollTop = top -= 50; //一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5); //定时调用函数使其更顺滑
    },
  },

  components: {
    // NavHeader,
    // PublicFooter,
    MeanHeader,
    Swiper,
  },
};
</script>

<style lang="scss">
.el-message-box {
  width: unset;
}
.el-message-box__wrapper {
  .el-message-box__btns {
    flex-wrap: nowrap;
    .el-button {
      display: flex;
      align-items: center;
      display: inline-block;
    }
  }
}
#Index {
  background: url("../../assets/images/mobile/indexbg.png");
  /* 背景图片覆盖整个容器 */
  background-size: cover;
  /* 背景图片固定在视窗中 */
  background-attachment: fixed;
  .music-bg {
    cursor: pointer;
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
    overflow: hidden;
    position: fixed;
    left: 0.44rem;
    top: 0.06rem;
    z-index: 999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .music-bg-run {
    animation: rotateBackground 10s linear infinite; /* 应用动画 */
    animation-play-state: paused;
  }
  .out-bj {
    width: 100%;
  }
  .scroll-top {
    position: fixed;
    right: 0.1rem;
    bottom: 1rem;
    width: 0.34rem;
    height: 0.34rem;
    background: url("../../assets/images/Rank/mb-top.png") no-repeat;
    background-size: 100% 100%;
  }

  .el-backtop {
    background: #4854c9;

    i {
      color: #fff;
    }
  }

  #output {
    width: 3.75rem;
    // padding-bottom: 0.075rem;
    margin: 0 auto;
  }

  .Tips {
    text-align: center;
    color: #2a9fd6;
    font-size: 0.14rem;
    line-height: 0.5rem;
    cursor: pointer;

    &:hover {
      color: #2fc8ff;
    }
  }
}
</style>
